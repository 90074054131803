<!-- Finder particular de Expedientes -->
<template>
  <div
    v-if="schema"
    class="exp_F"
  >
    <v-sheet
      v-show="!modal"
      :elevation="4"
    >
      <!-- Cabecera -->
      <div
        v-if="Entorno.header.header"
        class="cabecera"
      >
        <base_Header
          :entorno="Entorno.header"
          @onEvent="event_Header"
        />
      </div>

      <!-- Contenido -->
      <div class="contenedor">
        <!-- Filtro de Busqueda -->
        <div
          :style="Entorno.filtro.style"
          :class="Entorno.filtro.class"
        >
          <div style="display:flex; align-items:center">
            <div
              class="columna pr-5"
              style="width:35rem"
            >
              <!-- Campos Nº Control, Ref Cia, Fallecido, Póliza en multifilter -->
              <div style="display:flex; align-items:center">
                <ctrl_multiFilter
                  style="width:100%"
                  :items="items_multifilter"
                  :schema="schema"
                  :search-fn="buscarExec"
                  @buscar="buscarExec('buscarMultifilter')"
                />

                <!-- Botón de busqueda -->
                <v-btn
                  style="margin-left:2px"
                  v-bind="$cfg.btn.busca"
                  @click="buscarExec('buscarMultifilter')"
                >
                  <v-icon dark>
                    {{ $cfg.btn.busca.icono }}
                  </v-icon>
                </v-btn>
              </div>

              <!-- Campos Fecha y fecha fallecimiento en multifilter -->
              <div style="display:flex; align-items:center">
                <ctrl_multifilterFechas
                  :items="items_multifilterFechas"
                  :schema="schema"
                  :search-fn="buscarExec"
                />

                <v-btn
                  style="margin-left:2px"
                  v-bind="$cfg.btn.busca"
                  @click="buscarExec('buscarMultifilterFechas')"
                >
                  <v-icon dark>
                    {{ $cfg.btn.busca.icono }}
                  </v-icon>
                </v-btn>
              </div>
            </div>

            <div
              class="columna pr-2"
              style="width:25rem"
            >
              <!-- Campo Compañias sucursales y su botón -->
              <ctrlfinder
                finder-name="cias_F"
                :schema="schema.ctrls.cias"
              />

              <v-select
                v-model="schema.ctrls.zona.value"
                v-bind="$select"
                :label="schema.ctrls.zona.label"
                :items="ST_zonas"
                item-value="id"
                item-text="name"
                :disabled="zona >0"
              />
            </div>

            <div
              class="columna"
              style="width:10rem"
            >
              <!-- Botones de busqueda -->
              <v-btn
                v-bind="$cfg.btn.busca_with_text"
                @click="buscarExec('buscarEncurso')"
              >
                En curso
                <v-icon
                  right
                  dark
                >
                  {{ $cfg.btn.busca_with_text.icono }}
                </v-icon>
              </v-btn>

              <v-btn
                v-bind="$cfg.btn.busca_with_text"
                @click="buscarExec('buscarEnespera')"
              >
                En espera
                <v-icon
                  right
                  dark
                >
                  {{ $cfg.btn.busca_with_text.icono }}
                </v-icon>
              </v-btn>

              <v-btn
                v-bind="$cfg.btn.busca_with_text"
                @click="buscarExec('buscarUltimos')"
              >
                100 Últimos
                <v-icon
                  right
                  dark
                >
                  {{ $cfg.btn.busca_with_text.icono }}
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <!-- Grid -->
        <base_Fgrid
          :padre="stName"
          :entorno="Entorno"
          :items-per-page="15"
          no-data-text="No hay datos"
          dense
          @onEvent="event_Grid"
        >
          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">
            <div style="padding:10px 0 0 10px;">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })"
              />
            </div>
          </template>

          <template v-slot:control="{ Sitem }">
            <!-- <div style="display:flex;justify-content:space-between"> -->
            {{ Sitem.control }}
            <div class="light-blue--text text--darken-3">
              {{ Sitem.fhhr|fechahr }}
            </div>
            <!-- </div> -->
          </template>

          <template v-slot:fhhr="{ Sitem }">
            <!-- <div style="display:flex;justify-content:space-between"> -->
            {{ Sitem.fhhr| fechahr }}
            <!-- </div> -->
          </template>

          <!-- Slot APD1 -->
          <template v-slot:chkcom1="{ Sitem }">
            <div
              v-show="Sitem.name_apd1!= ''"
              style="display:flex;justify-content:space-between"
            >
              <v-chip
                x-small
                outlined
                label
                :color="Sitem.chkcom1>'0001'? 'blue-grey lighten-2' : 'red lighten-2'"
              >
                apd
              </v-chip>
              <v-chip
                x-small
                outlined
                label
                :color="Sitem.chkcall1>'0001'? 'blue-grey lighten-2' : 'red lighten-2'"
              >
                call
              </v-chip>
              <v-chip
                x-small
                outlined
                label
                :color="Sitem.chkcon1>'0001'?'blue-grey lighten-2':'red lighten-2'"
              >
                contac
              </v-chip>
              
              <v-chip
                x-small
                label
                :color="Sitem.chkfam1>'0001'?'blue-grey lighten-2':'red lighten-2'"
              >
                IAP
              </v-chip>
             
              <v-chip
                x-small
                label
                outlined
                :color="Sitem.chkfun1>'0001'? 'blue-grey lighten-2' : 'red lighten-2'"
              >
                OK fun
              </v-chip>
            </div>
          </template>

          <!-- Slot APD2 -->
          <template v-slot:chkcom2="{ Sitem }">
            <div
              v-show="Sitem.name_apd2!= ''"
              style="display:flex;justify-content:space-between"
            >
              <v-chip
                x-small
                outlined
                label
                :color="Sitem.chkcom2>'0001'? 'blue-grey lighten-2' : 'red lighten-2'"
              >
                apd
              </v-chip>
              <v-chip
                x-small
                outlined
                label
                :color="Sitem.chkcall2>'0001'? 'blue-grey lighten-2' : 'red lighten-2'"
              >
                call
              </v-chip>
              <v-chip
                x-small
                outlined
                label
                :color="Sitem.chkcon2>'0001'?'blue-grey lighten-2':'red lighten-2'"
              >
                contac
              </v-chip>
              <v-chip
                x-small
                label
                :color="Sitem.chkfam2>'0001'?'blue-grey lighten-2':'red lighten-2'"
              >
                IAP
              </v-chip>
              <v-chip
                x-small
                label
                outlined
                :color="Sitem.chkfun2>'0001'? 'blue-grey lighten-2' : 'red lighten-2'"
              >
                OK fun
              </v-chip>
            </div>
          </template>

          <!-- Slot CIERRES -->
          <template v-slot:chkconf24="{ Sitem }">
            <div style="display:flex;justify-content:space-between">
              <v-chip
                x-small
                outlined
                label
                :color="Sitem.chkpresapd==1?'blue-grey lighten-2' : 'red lighten-2'"
              >
                APD
              </v-chip>
              <v-chip
                x-small
                outlined
                label
                :color="Sitem.chkconf24==1?'blue-grey lighten-2':'red lighten-2'"
              >
                CTD
              </v-chip>
              <v-chip
                x-small
                outlined
                label
                :color="Sitem.chkconfadmon==1?'blue-grey lighten-2':'red lighten-2'"
              >
                ADMIN
              </v-chip>
            </div>
          </template>

          <!-- Slot campo acciones del Grid -->
          <!-- <template v-slot:acciones="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>    -->

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones="{ Sitem }">
            <div style="display:flex">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="mrow"
                @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })"
              />
            </div>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>
    <!-- Mto como ventana modal -->
    <!-- <v-overlay v-show=modal opacity=0> -->
    <!-- <div style="position:absolute"> -->
    <div v-show="modal">
      <component
        :is="component_M"
        :padre="stName"
        :accion="accion"
        :accion-row="accionRow"
        :disparo="disparo"
        @onEvent="event_Mto"
      />
    </div>
    <!-- </v-dialog> -->
    <!-- </v-overlay> -->
  </div>
</template>

<script>

import { mixinFinder } from '@/mixins/mixinFinder.js'
import plugs from '@/common/general_plugs'
const base_Header = () => plugs.groute('base_Header.vue', 'base')
const base_Fgrid = () => plugs.groute('base_Fgrid_maub.vue', 'base')
const btracombi = () => plugs.groute('btracombi.vue', 'comp')
const ctrl_multiFilter = () => plugs.groute('ctrl_multiFilter.vue', 'comp')
const ctrl_multifilterFechas = () => plugs.groute('ctrl_multifilterFechas.vue', 'comp')
const exp_M = () => plugs.groute('exp_M_Demo.vue', 'comp')
const ctrlfinder = () => plugs.groute('ctrlfinder.vue', 'comp')

export default {
  components: { base_Header, base_Fgrid, btracombi, ctrl_multiFilter, ctrl_multifilterFechas, exp_M, ctrlfinder },
  mixins: [mixinFinder],
  props: {
    padre: { type: String, default: '' },
    componenteTipo: { type: String, default: 'FM' },
    Entorno: { type: Object, default: function () { return JSON.parse(JSON.stringify(this.$cfg.base.F)) } }
  },

  data () {
    return {
      schema: null,
      api: 'exp_F',
      stName: 'stFexp',
      component_M: null,
      modal: false,
      accion: '',
      accionRow: {},
      disparo: false,

      headers: {},
      ST_zonas: [],
      items_multifilter: [],
      items_multifilterFechas: []
    }
  },

  methods: {
    async ini_data () {
      console.log('DEV ' + this.api + ' ********************** (ini_data)')

      // guardo Schema del store en variable local
      this.schema = this.$store.state[this.stName].schema

      // entorno
      this.Entorno.header.titulo = 'Expedientes'
      this.Entorno.grid.bind['items-per-page'] = -1

      this.headers = {
        header: [
          { text: 'M', value: 'acciones', sortable: false, width: '10', slot: true },
          { text: 'CONTROL', value: 'control', sortable: false, width: '110', slot: true },
          /* { text: "APERTURA", value: "fhhr", width:'110',slot:true }, */
          { text: 'FALLECIDO', value: 'fallename', width: '140' },
          { text: 'OPERA', value: 'op', width: '80' },
          { text: 'ALARMA', value: 'notice', width: '150' },
          { text: 'CIA', value: 'name_cia', width: '100' },
          { text: 'APD INICIAL', value: 'chkcom1', width: '275', slot: true },
          { text: 'APD FINAL', value: 'chkcom2', width: '275', slot: true },
          { text: 'PENDIENTE', value: 'notice_1', width: '150' },
          { text: 'CIERRES', value: 'chkconf24', width: '150', slot: true }

        ]
      }
      // Inicializo cabeceras
      this.Entorno.grid.headers = this.set_headers()

      // compruebo si lleva mto por el tipo de componente
      if (this.componenteTipo.indexOf('M') >= 0) this.component_M = 'exp_M'

      this.ST_zonas = this.$store.state.datos_iniciales.zonas
      this.ST_zonas.unshift({ id: '0', name: 'Todas' })

      //
      this.items_multifilter = [
        { ctrl: 'control', label: 'Nº Control', buscar: 'buscarControl' },
        { ctrl: 'callref1', label: 'Ref. CIA', buscar: 'buscarRefCia' },
        { ctrl: 'fallename', label: 'Fallecido', buscar: 'buscarFallename' },
        { ctrl: 'poliza', label: 'Póliza', buscar: 'buscarPoliza' },
        { ctrl: 'notice', label: 'Alerta', buscar: 'buscarAlerta' }
      ]
      //
      this.items_multifilterFechas = [
        { ctrl: 'fecha', label: 'Fecha alta', buscar: 'buscarFecha' },
        {
          ctrl: 'fallefhhr',
          label: 'Fecha fallecimiento',
          buscar: 'buscarFallefhhr'
        }
      ]
    },

    //
    ini_schemaCtrls () {
      this.schema.ctrls.zona.value = this.zona
    },

    // configuración particular campos del grid
    get_fieldGrid (item, header) {
      switch (header.value) {
        case 'fhhr':
          return this.sql2visual({ format: 'fhhr' }, item[header.value])

        default:
          return item[header.value]
      }
    }

  }
}
</script>
